import React, {useEffect, useState} from 'react';
import CompSelector from './CompSelector';
import ContextRunner from './ContextRunner';
import * as Tone from "tone";
let player, stopPlayer;
let stopCounter = 0;

const AudioPlayer = props => {
	const [activeState,setActiveState] = useState(false);


	const setStartPlayer = p => {
		// console.log("set start player",p);
		if (player && player.state === 'started') {
			player.stop();
		}	
		if (p) {
			player = p;
		}
		// console.log(player.loaded);
	}

	const setStopPlayer = p => {
		stopPlayer = p;
	}

	const reactivate = status => {
		if (status) {
			Tone.context.resume();
			if (!activeState)setActiveState(true);
		}
	}

  useEffect(_ => {
	  if (props.playState === 1 && Tone.context.state === 'running') {
	  	if (stopPlayer) {
		  	stopPlayer.stopAll();
	  	}
	  	if (player && player.loaded) {
	  		player.toDestination();
	  		Tone.start();
		  	player.start()
	  	}
	  }
	  else if (props.playState === 0 && Tone.context.state === 'running') {
	  	if (stopPlayer) {
		  	stopPlayer.player(stopCounter).start();
		  	stopCounter = (stopCounter > 1)?0:stopCounter+1;
	  	}
	  	if (player) {
	  		player.stop();
	  	}
	  }	
  },[props.playState])
	

	if (props.data !== 0) {	
		return (
			<div id="audioPlayer">
				<CompSelector 
					setStartPlayer={setStartPlayer} 
					setStopPlayer={setStopPlayer}
				/>
				<ContextRunner 
					reactivate={reactivate}
				/>
			</div>
		)
		
	}
};

export default AudioPlayer;
