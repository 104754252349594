import React, {useEffect} from 'react';
import {allPlayers, allStopPlayers} from './allPlayers';
import {useSessionStorage} from './hooks';
let player,sPlayer;
let seasonList = ["nothing","I - Winter","II - Lente","III - Zomer","IV - Herfst"];

const CompSelector = props => {
	const [comp, setComp] = useSessionStorage('composition',0);
	const [season,setSeason] = useSessionStorage('season',0);

	const chooseDifferentComp = e => {
		setComp(parseInt(e.target.value));
		setSeason(seasonList[parseInt(e.target.value)])
	}

	const changeCompSelection = e => {
		let chosenComp = e.target.value
		setComp(chosenComp);
		setSeason(seasonList[chosenComp])		
	}

	useEffect(_ => {
		player = allPlayers[comp-1];
		sPlayer = allStopPlayers[comp-1];
		props.setStartPlayer(player);
		props.setStopPlayer(sPlayer);
	},[comp,props]);

	if (comp === 0) {
		return (
			<div className="CompSelect">
				<table>
					<tbody>
						<tr>
							<td>
								<span>1. Choose your track</span>
							</td>
						</tr>
						<tr>
							<td>
								<table className="seasonTable">
								<tbody>
									<tr>
										<td className="leftCell">
											<button className = "seasonButton" onClick={changeCompSelection} value={1}>I - Winter</button>
										</td>
										<td className="rightCell">
											<button className = "seasonButton" onClick={changeCompSelection} value={2}>II - Lente</button>
										</td>
									</tr>
									<tr>
										<td className="leftCell">
											<button className = "seasonButton" onClick={changeCompSelection} value={3}>III - Zomer</button>
										</td>
										<td className="rightCell">
											<button className = "seasonButton" onClick={changeCompSelection} value={4}>IV - Herfst</button>
										</td>
									</tr>
									</tbody>
								</table>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
	else {
		return (
			<div className="CompSelect">
				<table>
					<tbody>
						<tr>
							<td>
								<button onClick={chooseDifferentComp} value={0}>Go to the next track</button>
							</td>
						</tr>
						<tr>
							<td>Current track: <strong> {season} </strong> </td>
						</tr>
					</tbody>
					</table>
			</div>
		)
	}
}

export default CompSelector;