import React, {useState,useEffect} from 'react';
import * as Tone from "tone";

const ContextRunner = props => {
	const [audioState,setAudioState] = useState(false);
	if (audioState) {

	}
	const startContext = _=> {
    Tone.context.resume();
    setAudioState(true);
    props.reactivate(true);
	}

	const focus = _ => {
		window.location.reload(false);
	}
	const blur = _ => {
		console.log("there is blur");
	}
	

	useEffect(() => {
		if (Tone.context.state !== 'running') {
			setAudioState(false);
		}	
		window.addEventListener('focus',focus);
		window.addEventListener('blur',blur);	
	},[])


	// If the audio-context is active, show keep phone awake message
	// Else show the start button
	// If something's wronng with reactivating audio context after refresh or something: 
	// Change && in if statement below to ||
	// --------------------------------------------
	if (Tone.context.state !== 'running' && !audioState) {
	  return (
	  	<div id="contextStarter">
		  	<table>
		      <tbody>
		      <tr>
		        <td>
		          2. Click start to begin<br/>
		        </td>
		      </tr>
		      <tr>
		        <td>
		          <button id="buttons" onClick={startContext}>Start</button>  
		        </td>
		      </tr>
		      </tbody>
		    </table>
	    </div>
		);
	}
	else {
		return(
			<div id="phoneAwake">
				<table>
					<tbody>
					<tr>
						<td>
							Keep your phone awake<br/>
						</td>
					</tr>
					<tr>
						<td>
							touch your screen regularly<br/>
						</td>
					</tr>
					</tbody>
				</table>
			</div>
		);
	}
}

export default ContextRunner;