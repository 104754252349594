import winterComposition from '../assets/Fanfare_I_Winter.mp3';
import winterStop1 from '../assets/Fanfare_I_Winter_Stop_1.mp3';
import winterStop2 from '../assets/Fanfare_I_Winter_Stop_2.mp3';
import winterStop3 from '../assets/Fanfare_I_Winter_Stop_3.mp3';
import springComposition from '../assets/Fanfare_II_Lente.mp3';
import springStop1 from '../assets/Fanfare_II_Lente_Stop_1.mp3';
import springStop2 from '../assets/Fanfare_II_Lente_Stop_2.mp3';
import springStop3 from '../assets/Fanfare_II_Lente_Stop_3.mp3';
import summerComposition from '../assets/Fanfare_III_Zomer.mp3';
import summerStop1 from '../assets/Fanfare_III_Zomer_Stop_1.mp3';
import summerStop2 from '../assets/Fanfare_III_Zomer_Stop_2.mp3';
import summerStop3 from '../assets/Fanfare_III_Zomer_Stop_3.mp3';
import autumnComposition from '../assets/Fanfare_IV_Herfst.mp3';
import autumnStop1 from '../assets/Fanfare_IV_Herfst_Stop_1.mp3';
import autumnStop2 from '../assets/Fanfare_IV_Herfst_Stop_2.mp3';
import autumnStop3 from '../assets/Fanfare_IV_Herfst_Stop_3.mp3';
import * as Tone from "tone";

const winter = [winterStop1, winterStop2, winterStop3];
const spring = [springStop1, springStop2, springStop3];
const summer = [summerStop1, summerStop2, summerStop3];
const autumn = [autumnStop1, autumnStop2, autumnStop3];

console.log("heye!");

const winterPlayer = new Tone.Player(winterComposition).toDestination();
winterPlayer.fadeOut = 0.125;
winterPlayer.loop = true;
const winterStopPlayer = new Tone.Players().toDestination();
for (let i=0; i<3; i++) {
	winterStopPlayer.add(i, winter[i]);
}
const springPlayer = new Tone.Player(springComposition).toDestination();
springPlayer.fadeOut = 0.125;
springPlayer.loop = true;
const springStopPlayer = new Tone.Players().toDestination();
for (let i=0; i<3; i++) {
	springStopPlayer.add(i, spring[i]);
}
const summerPlayer = new Tone.Player(summerComposition).toDestination();
summerPlayer.fadeOut = 0.125;
summerPlayer.loop = true;
const summerStopPlayer = new Tone.Players().toDestination();
for (let i=0; i<3; i++) {
	summerStopPlayer.add(i, summer[i]);
}
const autumnPlayer = new Tone.Player(autumnComposition).toDestination();
autumnPlayer.fadeOut = 0.125;
autumnPlayer.loop = true;
const autumnStopPlayer = new Tone.Players().toDestination();
for (let i=0; i<3; i++) {
	autumnStopPlayer.add(i, autumn[i]);
}
export const allPlayers = [winterPlayer,springPlayer,summerPlayer,autumnPlayer];

export const allStopPlayers = [winterStopPlayer,springStopPlayer,summerStopPlayer,autumnStopPlayer];